//  Core

@use "sass:math";

.theme-configurator {
  display: none;
}

@include media-breakpoint-up(md) {

  .theme-configurator {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1165;
    display: block;
    height: 100vh;
    transform: translate($theme-configurator-drawer-width);
    transition: $theme-configurator-transition;


    &--slideout {
      margin-left: -$theme-configurator-btn-size;
      width: $theme-configurator-btn-size;
      position: absolute;
      right: 100%;
      top: 30%;
      z-index: 4;

      .btn.btn-toggle-config-drawer {
        width: math.div($theme-configurator-btn-size, 1.5);
        height: math.div($theme-configurator-btn-size, 1.5);
      }
    }

    &--heading {
      font-size: $theme-configurator-heading-font-size;
      padding: math.div($spacer, 1.5) ($spacer);
      background: $theme-configurator-heading-bg;
      border-color: $theme-configurator-heading-border-color;
      border-width: 1px 0;
      border-style: solid;
      font-weight: bold;
      text-transform: uppercase;
      color: $theme-configurator-heading-color;
      margin: 0;
    }

    &--list {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: ($spacer);
        display: flex;
        align-items: center;
        border-bottom: $border-color solid 1px;

        &:last-child {
          border-color: transparent;
        }
      }

      &__heading {
        font-weight: bold;

        span {
          display: block;
          opacity: .6;
          font-weight: normal;
          font-size: $font-size-sm !important;
        }
      }
    }

    &--drawer {
      background: $theme-configurator-drawer-bg;
      width: ($theme-configurator-drawer-width);
      height: 100%;
      box-shadow: 0 0 0 0 transparent;
      transition: $theme-configurator-transition;
      position: relative;
      z-index: 5;
    }

    &--swatches {
      text-align: center;
      padding: ($spacer * 0.5);

      .theme-config-swatch {
        width: 32px;
        height: 32px;
        margin: ($spacer * 0.5);
        @include border-radius($border-radius-sm);
        display: inline-block;
        opacity: .7;
        z-index: 10;
        position: relative;
        transition: $transition-base;
        cursor: pointer;
        border: $white solid 1px;
        transform-origin: center;

        &.active,
        &:hover {
          opacity: 1;
          transform: scale(1.3);
          z-index: 15;
          box-shadow: 0 0 0 2px $primary;
        }

        &--lg {
          width: 38px;
          height: 38px;
          border: $white solid 2px;
          box-shadow: 0 0 0 1px $gray-700;
        }
      }
    }

    .bg-current-scheme {
      width: 100%;
      height: 34px;
      @include border-radius($border-radius-sm);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .theme-configurator-open {
    .theme-configurator {
      transform: translate(0);

      &--drawer {
        box-shadow: $theme-configurator-open-box-shadow;
      }
    }
  }
}
