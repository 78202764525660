.demo-img-wrapper:hover {
    border-color: var(--primary)
}

body .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary)
}

:root {
    --second: var(--secondary);
}

.badge-primary {
    background-color: var(--primary)
}

.badge-secondary {
    background-color: var(--secondary)
}

.badge-second {
    background-color: var(--secondary)
}

.bg-primary {
    background-color: var(--primary) !important
}

.bg-secondary {
    background-color: var(--secondary) !important
}

.bg-second {
    background-color: var(--secondary) !important
}

.border-primary {
    border-color: var(--primary) !important
}

.border-secondary {
    border-color: var(--secondary) !important
}

.border-second {
    border-color: var(--secondary) !important
}

.text-primary {
    color: var(--primary) !important
}

.text-secondary {
    color: var(--secondary) !important
}

.text-second {
    color: var(--secondary) !important
}

.dropzone-upload-wrapper .dropzone-inner-wrapper:hover {
    border-color: var(--primary);
}

.rdw-colorpicker-modal-style-label-active {
    border-bottom: 2px solid var(--primary)
}

.rdw-embedded-modal-header-label {
    border-bottom: 2px solid var(--primary)
}

.rdw-image-modal-header-label-highlighted {
    border-bottom: 2px solid var(--primary)
}

.rdw-image-modal-upload-option-highlighted {
    outline: 2px dashed var(--primary)
}

.rbc-today {
    background-color: var(--primary)
}

.rbc-toolbar {
    background: var(--secondary);
}

    .rbc-toolbar button {
        background-color: var(--primary);
        color: var(--primary);
        border: 2px solid var(--primary);
    }

        .rbc-toolbar button:hover {
            background-color: var(--primary);
            border-color: var(--primary)
        }

        .rbc-toolbar button.disabled, .rbc-toolbar button:disabled {
            background-color: var(--primary);
            border-color: var(--primary)
        }

        .rbc-toolbar button.rbc-active, .rbc-toolbar button:not(:disabled):active, .rbc-toolbar button:not(:disabled):focus, .rbc-toolbar button:not(:disabled):hover {
            background: var(--primary);
        }

.rbc-event {
    background-color: var(--primary);
}

.rbc-month-row:hover > div > .rbc-day-bg {
    background: var(--secondary)
}

.slick-next, .slick-prev {
    color: var(--primary);
}

.slick-dots li.slick-active button {
    background: var(--primary)
}

.slider-arrows-dark .slick-next, .slider-arrows-dark .slick-prev {
    background: var(--primary);
}

    .slider-arrows-dark .slick-next:hover, .slider-arrows-dark .slick-prev:hover {
        color: var(--primary)
    }

.loading-bullet {
    color: var(--primary)
}

.circular-progress-primary .CircularProgressbar-path {
    stroke: var(--primary)
}

.circular-progress-primary .CircularProgressbar-text {
    fill: var(--primary)
}

.circular-progress-secondary .CircularProgressbar-path {
    stroke: var(--secondary)
}

.circular-progress-secondary .CircularProgressbar-text {
    fill: var(--secondary)
}

.circular-progress-second .CircularProgressbar-path {
    stroke: var(--secondary)
}

.circular-progress-second .CircularProgressbar-text {
    fill: var(--secondary)
}

.MuiButton-root.btn-primary {
    background: var(--primary)
}

    .MuiButton-root.btn-primary:not(:disabled):hover {
        background: var(--primary)
    }

.MuiButton-root.btn-outline-primary {
    color: var(--primary);
    border: 2px solid var(--primary)
}

    .MuiButton-root.btn-outline-primary:hover, .MuiButton-root.btn-outline-primary:not(:disabled).active {
        background: var(--primary);
    }

.MuiButtonBase-root.btn-neutral-primary {
    color: var(--primary);
}

    .MuiButtonBase-root.btn-neutral-primary.active, .MuiButtonBase-root.btn-neutral-primary:hover {
        background: var(--primary);
    }

.btn-link-primary span {
    color: var(--primary)
}

    .btn-link-primary span:before {
        background-color: var(--primary)
    }

.MuiButton-root.btn-secondary {
    background: var(--secondary)
}

    .MuiButton-root.btn-secondary:not(:disabled):hover {
        background: var(--secondary);
    }

.MuiButton-root.btn-outline-secondary {
    color: var(--secondary);
    border: 2px solid var(--secondary)
}

    .MuiButton-root.btn-outline-secondary:hover, .MuiButton-root.btn-outline-secondary:not(:disabled).active {
        background: var(--secondary);
    }

.MuiButtonBase-root.btn-neutral-secondary {
    color: var(--secondary);
}

    .MuiButtonBase-root.btn-neutral-secondary.active, .MuiButtonBase-root.btn-neutral-secondary:hover {
        background: var(--secondary);
    }

.btn-link-secondary span {
    color: var(--secondary)
}

    .btn-link-secondary span:before {
        background-color: var(--secondary)
    }

.MuiButton-root.btn-second {
    background: var(--secondary)
}

    .MuiButton-root.btn-second:not(:disabled):hover {
        background: var(--secondary)
    }

.MuiButton-root.btn-outline-second {
    color: var(--secondary);
    border: 2px solid var(--secondary)
}

    .MuiButton-root.btn-outline-second:hover, .MuiButton-root.btn-outline-second:not(:disabled).active {
        background: var(--secondary);
    }

.MuiButtonBase-root.btn-neutral-second {
    color: var(--secondary);
}

    .MuiButtonBase-root.btn-neutral-second.active, .MuiButtonBase-root.btn-neutral-second:hover {
        background: var(--secondary);
    }

.btn-link-second span {
    color: var(--secondary)
}

    .btn-link-second span:before {
        background-color: var(--secondary)
    }

.btn-swatch.active, .btn-swatch:hover {
    box-shadow: 0 0 0 2px var(--primary)
}

.MuiPickersToolbar-toolbar, .MuiTab-root {
    background-color: var(--primary) !important
}

.MuiPickerDTTabs-tabs, .MuiPickersToolbar-toolbar-root {
    background-color: var(--primary) !important
}

.MuiPickersClockPointer-thumb {
    border: 14px solid var(--primary) !important
}

.MuiPickersClock-pin, .MuiPickersClockNumber-clockNumberSelected, .MuiPickersClockPointer-noPoint, .MuiPickersClockPointer-pointer, .MuiPickersDay-daySelected {
    background-color: var(--primary) !important
}

body .MuiChip-root {
    background: var(--primary);
}

.react-joyride__tooltip div button {
    background: var(--primary) !important;
}

.MuiList-root.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected {
    color: var(--primary);
    border-top: 3px solid var(--primary)
}

    .MuiList-root.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover {
        color: var(--primary)
    }

.MuiList-root.nav-line .MuiListItem-root.MuiListItem-button .divider {
    background: var(--primary);
}

.MuiList-root.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected {
    color: var(--primary);
}

    .MuiList-root.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover {
        color: var(--primary)
    }

.MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button:before {
    background: var(--primary);
}

.MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected, .MuiList-root.tabs-animated.nav-tabs .MuiListItem-root.MuiListItem-button:hover {
    color: var(--primary);
}

.MuiList-root.nav-tabs-primary.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected {
    color: var(--primary);
    border-top: 3px solid var(--primary)
}

    .MuiList-root.nav-tabs-primary.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover, .MuiList-root.nav-tabs-primary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected, .MuiList-root.nav-tabs-primary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover {
        color: var(--primary)
    }

        .MuiList-root.nav-tabs-primary.nav-line .MuiListItem-root.MuiListItem-button .divider, .MuiList-root.nav-tabs-primary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before, .MuiList-root.nav-tabs-primary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before {
            background: var(--primary)
        }

.MuiList-root.nav-tabs-primary.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected, .MuiList-root.nav-tabs-primary.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover {
    color: var(--primary)
}

.MuiList-root.nav-tabs-primary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before {
    background: var(--primary) !important;
}

.MuiList-root.nav-tabs-secondary.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected {
    color: var(--secondary);
    border-top: 3px solid var(--secondary)
}

    .MuiList-root.nav-tabs-secondary.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover, .MuiList-root.nav-tabs-secondary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected, .MuiList-root.nav-tabs-secondary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover {
        color: var(--secondary)
    }

        .MuiList-root.nav-tabs-secondary.nav-line .MuiListItem-root.MuiListItem-button .divider, .MuiList-root.nav-tabs-secondary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before, .MuiList-root.nav-tabs-secondary.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before {
            background: var(--secondary)
        }

.MuiList-root.nav-tabs-secondary.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected, .MuiList-root.nav-tabs-secondary.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover {
    color: var(--secondary)
}

.MuiList-root.nav-tabs-secondary.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before {
    background: var(--secondary) !important;
}

.MuiList-root.nav-tabs-second.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected {
    color: var(--secondary);
    border-top: 3px solid var(--secondary)
}

    .MuiList-root.nav-tabs-second.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:hover, .MuiList-root.nav-tabs-second.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected, .MuiList-root.nav-tabs-second.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover {
        color: var(--secondary)
    }

        .MuiList-root.nav-tabs-second.nav-line .MuiListItem-root.MuiListItem-button .divider, .MuiList-root.nav-tabs-second.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button.Mui-selected:before, .MuiList-root.nav-tabs-second.tabs-animated-line.nav-tabs .MuiListItem-root.MuiListItem-button:hover:before {
            background: var(--secondary)
        }

.MuiList-root.nav-tabs-second.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected, .MuiList-root.nav-tabs-second.nav-line .MuiListItem-root.MuiListItem-button.Mui-selected:hover {
    color: var(--secondary)
}

.MuiList-root.nav-tabs-second.nav-tabs.tabs-animated-shadow .MuiListItem-root.MuiListItem-button:before {
    background: var(--secondary) !important;
}

.nav-neutral-primary .MuiListItem-root.MuiListItem-button.Mui-selected, .nav-neutral-primary .MuiListItem-root.MuiListItem-button:hover {
    color: var(--primary);
}

    .nav-neutral-primary .MuiListItem-root.MuiListItem-button.Mui-selected:before, .nav-neutral-primary .MuiListItem-root.MuiListItem-button:hover:before {
        background: var(--primary)
    }

.nav-primary .MuiListItem-root.MuiListItem-button.Mui-selected, .nav-primary .MuiListItem-root.MuiListItem-button:hover {
    background: var(--primary)
}

    .nav-primary .MuiListItem-root.MuiListItem-button.Mui-selected:before, .nav-primary .MuiListItem-root.MuiListItem-button:hover:before {
        background: var(--primary)
    }

.nav-neutral-secondary .MuiListItem-root.MuiListItem-button.Mui-selected, .nav-neutral-secondary .MuiListItem-root.MuiListItem-button:hover {
    color: var(--secondary);
}

    .nav-neutral-secondary .MuiListItem-root.MuiListItem-button.Mui-selected:before, .nav-neutral-secondary .MuiListItem-root.MuiListItem-button:hover:before {
        background: var(--secondary)
    }

.nav-secondary .MuiListItem-root.MuiListItem-button.Mui-selected, .nav-secondary .MuiListItem-root.MuiListItem-button:hover {
    background: var(--secondary)
}

    .nav-secondary .MuiListItem-root.MuiListItem-button.Mui-selected:before, .nav-secondary .MuiListItem-root.MuiListItem-button:hover:before {
        background: var(--secondary)
    }

.nav-neutral-second .MuiListItem-root.MuiListItem-button.Mui-selected, .nav-neutral-second .MuiListItem-root.MuiListItem-button:hover {
    color: var(--secondary);
}

    .nav-neutral-second .MuiListItem-root.MuiListItem-button.Mui-selected:before, .nav-neutral-second .MuiListItem-root.MuiListItem-button:hover:before {
        background: var(--secondary)
    }

.nav-second .MuiListItem-root.MuiListItem-button.Mui-selected, .nav-second .MuiListItem-root.MuiListItem-button:hover {
    background: var(--secondary)
}

    .nav-second .MuiListItem-root.MuiListItem-button.Mui-selected:before, .nav-second .MuiListItem-root.MuiListItem-button:hover:before {
        background: var(--secondary)
    }

.MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button.Mui-selected, .MuiList-root.nav-transparent-alt .MuiListItem-root.MuiListItem-button:hover {
    color: var(--primary)
}

.toastr-primary .MuiSnackbarContent-root {
    background: var(--primary);
}

.toastr-secondary .MuiSnackbarContent-root {
    background: var(--secondary);
}

.toastr-second .MuiSnackbarContent-root {
    background: var(--secondary);
}

.MuiPagination-root .MuiPaginationItem-root:hover {
    color: var(--primary);
}

.MuiPagination-root.pagination-primary .MuiPaginationItem-root:hover {
    color: var(--primary);
    border-color: var(--primary)
}

.MuiPagination-root.pagination-primary .MuiPaginationItem-root.Mui-selected {
    background: var(--primary);
    border-color: var(--primary);
}

.MuiPagination-root.pagination-secondary .MuiPaginationItem-root:hover {
    color: var(--secondary);
    border-color: var(--secondary)
}

.MuiPagination-root.pagination-secondary .MuiPaginationItem-root.Mui-selected {
    background: var(--secondary);
    border-color: var(--secondary);
}

.MuiPagination-root.pagination-second .MuiPaginationItem-root:hover {
    color: var(--secondary);
    border-color: var(--secondary)
}

.MuiPagination-root.pagination-second .MuiPaginationItem-root.Mui-selected {
    background: var(--secondary);
    border-color: var(--secondary);
}

.MuiLinearProgress-root.progress-bar-primary .MuiLinearProgress-bar {
    background: var(--primary)
}

.MuiCircularProgress-root.progress-bar-primary {
    color: var(--primary)
}

.MuiLinearProgress-root.progress-bar-secondary .MuiLinearProgress-bar {
    background: var(--secondary)
}

.MuiCircularProgress-root.progress-bar-secondary {
    color: var(--secondary)
}

.MuiLinearProgress-root.progress-bar-second .MuiLinearProgress-bar {
    background: var(--secondary)
}

.MuiCircularProgress-root.progress-bar-second {
    color: var(--secondary)
}

.ribbon-primary > small {
    background-color: var(--primary)
}

.ribbon-secondary > small {
    background-color: var(--secondary)
}

.ribbon-second > small {
    background-color: var(--secondary)
}

.ribbon-horizontal--primary {
    background: var(--primary);
}

.ribbon-horizontal--secondary {
    background: var(--secondary);
}

.ribbon-horizontal--second {
    background: var(--secondary);
}

.ribbon-vertical--primary {
    background: var(--primary);
}

    .ribbon-vertical--primary:after {
        border-left-color: var(--primary);
        border-right-color: var(--primary)
    }

.ribbon-vertical--secondary {
    background: var(--secondary);
}

    .ribbon-vertical--secondary:after {
        border-left-color: var(--secondary);
        border-right-color: var(--secondary)
    }

.ribbon-vertical--second {
    background: var(--secondary);
}

    .ribbon-vertical--second:after {
        border-left-color: var(--secondary);
        border-right-color: var(--secondary)
    }

.search-wrapper.is-active .MuiTextField-root {
    border-color: var(--primary)
}

.search-wrapper .search-options div input:checked + label svg path {
    fill: var(--primary) !important
}

.search-wrapper .search-options div input:checked + label span {
    background: var(--primary)
}

    .search-wrapper .search-options div input:checked + label span:after {
        border-top-color: var(--primary)
    }

.MuiSlider-root.slider-primary {
    color: var(--primary)
}

    .MuiSlider-root.slider-primary.MuiSlider-trackInverted .MuiSlider-markActive, .MuiSlider-root.slider-primary.MuiSlider-trackInverted .MuiSlider-track {
        background: var(--primary)
    }

    .MuiSlider-root.slider-primary .MuiSlider-thumb {
        border-color: var(--primary)
    }

.MuiSlider-root.slider-secondary {
    color: var(--secondary)
}

    .MuiSlider-root.slider-secondary.MuiSlider-trackInverted .MuiSlider-markActive, .MuiSlider-root.slider-secondary.MuiSlider-trackInverted .MuiSlider-track {
        background: var(--secondary)
    }

    .MuiSlider-root.slider-secondary .MuiSlider-thumb {
        border-color: var(--secondary)
    }

.MuiSlider-root.slider-second {
    color: var(--secondary)
}

    .MuiSlider-root.slider-second.MuiSlider-trackInverted .MuiSlider-markActive, .MuiSlider-root.slider-second.MuiSlider-trackInverted .MuiSlider-track {
        background: var(--secondary)
    }

    .MuiSlider-root.slider-second .MuiSlider-thumb {
        border-color: var(--secondary)
    }

body .MuiSwitch-root.toggle-switch-primary:hover .MuiSwitch-track {
    border-color: var(--primary)
}

body .MuiSwitch-root.toggle-switch-primary .Mui-checked {
    color: var(--primary)
}

    body .MuiSwitch-root.toggle-switch-primary .Mui-checked + .MuiSwitch-track {
        background: var(--primary);
        border-color: var(--primary)
    }

body .MuiSwitch-root.toggle-switch-secondary:hover .MuiSwitch-track {
    border-color: var(--secondary)
}

body .MuiSwitch-root.toggle-switch-secondary .Mui-checked {
    color: var(--secondary)
}

    body .MuiSwitch-root.toggle-switch-secondary .Mui-checked + .MuiSwitch-track {
        background: var(--secondary);
        border-color: var(--secondary)
    }

body .MuiSwitch-root.toggle-switch-second:hover .MuiSwitch-track {
    border-color: var(--secondary)
}

body .MuiSwitch-root.toggle-switch-second .Mui-checked {
    color: var(--secondary)
}

    body .MuiSwitch-root.toggle-switch-second .Mui-checked + .MuiSwitch-track {
        background: var(--secondary);
        border-color: var(--secondary)
    }

body .MuiTabs-root.nav-tabs-primary .MuiTabs-indicator {
    background: var(--primary) !important;
}

body .MuiTabs-root.nav-tabs-primary .MuiTab-root:hover {
    color: var(--primary)
}

body .MuiTabs-root.nav-tabs-secondary .MuiTabs-indicator {
    background: var(--secondary) !important;
}

body .MuiTabs-root.nav-tabs-secondary .MuiTab-root:hover {
    color: var(--secondary)
}

body .MuiTabs-root.nav-tabs-second .MuiTabs-indicator {
    background: var(--secondary) !important;
}

body .MuiTabs-root.nav-tabs-second .MuiTab-root:hover {
    color: var(--secondary)
}

.timeline-list.timeline-list--primary .timeline-item--icon, .timeline-list.timeline-list--primary:before {
    background: var(--primary)
}

.timeline-list.timeline-list--secondary .timeline-item--icon, .timeline-list.timeline-list--secondary:before {
    background: var(--secondary)
}

.timeline-list.timeline-list--second .timeline-item--icon, .timeline-list.timeline-list--second:before {
    background: var(--secondary)
}

.MuiTooltip-tooltip.tooltip-primary {
    background: var(--primary);
}

    .MuiTooltip-tooltip.tooltip-primary .MuiTooltip-arrow {
        color: var(--primary)
    }

.MuiTooltip-tooltip.tooltip-secondary {
    background: var(--secondary);
}

    .MuiTooltip-tooltip.tooltip-secondary .MuiTooltip-arrow {
        color: var(--secondary)
    }

.MuiTooltip-tooltip.tooltip-second {
    background: var(--secondary);
}

    .MuiTooltip-tooltip.tooltip-second .MuiTooltip-arrow {
        color: var(--secondary)
    }

.treeview-primary.MuiTreeView-root .MuiTreeItem-root .MuiTreeItem-content:hover {
    color: var(--primary)
}

.treeview-alt.MuiTreeView-root .MuiTreeItem-root .MuiTreeItem-content:hover {
    color: var(--primary)
}

.app-header .hamburger-inner, .app-header .hamburger-inner:after, .app-header .hamburger-inner:before {
    background: var(--primary) !important
}

.app-sidebar--dark .sidebar-menu-collapsed li a.active {
    color: var(--primary)
}

.app-sidebar--dark .app-sidebar--header .expand-sidebar-btn {
    color: var(--primary);
}

.app-sidebar--light .app-sidebar--header .collapse-sidebar-btn:hover {
    color: var(--primary)
}

.app-sidebar--light .app-sidebar--header .expand-sidebar-btn {
    border: 2px solid var(--primary);
    background: var(--primary);
}

.app-sidebar--light .app-sidebar-logo--icon {
    background: var(--primary);
}

.app-sidebar--light .app-sidebar-logo--text span {
    color: var(--primary);
}

.app-sidebar--light .hamburger-inner, .app-sidebar--light .hamburger-inner:after, .app-sidebar--light .hamburger-inner:before {
    background: var(--primary) !important
}

.app-sidebar--light .app-sidebar--userbox .btn-userbox:hover {
    color: var(--primary)
}

.app-sidebar--light .sidebar-navigation ul li > a .sidebar-icon > * {
    color: var(--primary);
}

.app-sidebar--light .sidebar-navigation ul li > a.active, .app-sidebar--light .sidebar-navigation ul li > a:hover {
    color: var(--primary);
}

    .app-sidebar--light .sidebar-navigation ul li > a.active .sidebar-icon > *, .app-sidebar--light .sidebar-navigation ul li > a:hover .sidebar-icon > * {
        color: var(--primary)
    }

.app-sidebar--light .app-sidebar--footer .MuiButton-root.active, .app-sidebar--light .app-sidebar--footer .MuiButton-root:active, .app-sidebar--light .app-sidebar--footer .MuiButton-root:focus, .app-sidebar--light .app-sidebar--footer .MuiButton-root:hover {
    color: var(--primary);
}

    .app-sidebar--light .app-sidebar--footer .MuiButton-root.active svg, .app-sidebar--light .app-sidebar--footer .MuiButton-root:active svg, .app-sidebar--light .app-sidebar--footer .MuiButton-root:focus svg, .app-sidebar--light .app-sidebar--footer .MuiButton-root:hover svg {
        fill: var(--primary)
    }

@media (min-width:768px) {
    .theme-configurator--swatches .theme-config-swatch.active, .theme-configurator--swatches .theme-config-swatch:hover {
        box-shadow: 0 0 0 2px var(--primary)
    }
}

* {
    font-family: customFont, Arial, Helvetica, sans-serif !important;
}

.primaryColor {
    color: var(--primary);
}

.googleMarkerLabelPrimary {
    color: red !important;
    font-size: 14px;
    font-family: customFont, Arial, Helvetica, sans-serif !important;
    font-weight: bold;
    margin-left: 50px;
    margin-bottom: 20px;
}

.googleMarkerLabelSecondary {
    color: blue !important;
    font-size: 14px;
    font-family: customFont, Arial, Helvetica, sans-serif !important;
    font-weight: bold;
    margin-left: 50px;
    margin-bottom: 20px;
}

.pac-container {
    z-index: 10000 !important;
}

.bid-deleted {
    background-color: #f5b9b5;
}


.MuiLinearProgress-colorPrimary {
    background-color: white !important;
    height: 0.2rem !important;
    
}

.MuiLinearProgress-bar {
    background-color: var(--primary) !important;
}