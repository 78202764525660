// Core
.MuiPickersToolbar-toolbar {
    background-color: $first !important; 
}

.MuiTab-root {
    background-color: $first !important; 
    &.Mui-selected {
        box-shadow: 16px 16px 26px -10px rgba($secondary, 0.56),
        4px 4px 25px 0px rgba(0, 0, 0, 0.12),
        8px 8px 25px -5px rgba($secondary, 0.2);
    }
}

.MuiPickersToolbar-toolbar-root {
    background-color: $first !important; 
}

.MuiPickerDTTabs-tabs {
    background-color: $first !important
}

.MuiPickersClockPointer-thumb {
    background-color: $secondary !important;
    border: 14px solid $first !important;
}

.MuiPickersClock-pin {
    background-color: $first !important;
}

.MuiPickersDay-daySelected {
    background-color: $first !important;
}

.MuiPickersClockNumber-clockNumberSelected  {
    background-color: $first !important;
}

.MuiPickersClockPointer-pointer {
    background-color: $first !important;
}